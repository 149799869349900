import React from 'react';
import './Contact.css';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaClock, FaDirections } from 'react-icons/fa';

const Contact = () => {
  return (
    <div className="contact-section">
      <div className="contact-container">
        <div className="contact-info">
          <h2 className="contact-header">Contact Us</h2>
          <p className="contact-description">
            We’re here to help! Get in touch with us for any inquiries or support.
          </p>
          <div className="contact-details">
            <p><FaPhone className="colored-icon phone-icon" /> +255 674 299 674</p>
            <p><FaEnvelope className="colored-icon email-icon" /> info@kelvinmotobike.co.tz</p>
            <p>
              <FaMapMarkerAlt className="colored-icon location-icon" /> 
              Zanzibar, Tanzania
              <a href="https://www.google.com/maps/place/Zanzibar,+Tanzania" target="_blank" rel="noopener noreferrer" className="map-link">
                <FaDirections className="colored-icon directions-icon" /> 
              </a>
            </p>
            <p><FaClock className="colored-icon clock-icon" /> Mon - Sat: 24/7</p>
          </div>
        </div>
        <div className="contact-form">
          <h3 className="form-header">Send Us a Message</h3>
          <form>
            <input type="text" name="name" placeholder="Your Name" required />
            <input type="email" name="email" placeholder="Your Email" required />
            <input type="text" name="subject" placeholder="Subject" required />
            <textarea name="message" placeholder="Your Message" rows="5" required></textarea>
            <button type="submit" className="submit-btn">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
