import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import Booking from "./components/Booking";
import Order from './components/Order';

function App() {
  return (
    <div className="App">
     <Helmet>
  <link rel="icon" href="/favicon.ico?v=1" type="image/x-icon" />
  <link rel="shortcut icon" href="/favicon.ico?v=1" type="image/x-icon" />
</Helmet>

      <Router>
        <Routes>
           <Route path="/" element={<Home />} />
           <Route path="/aboutUs" element={<AboutUs/>} /> 
           <Route path='/contactUs' element = {<ContactUs/>}/>
           <Route path='/booking' element={<Booking/>}/>
           <Route path='/order' element={<Order/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
