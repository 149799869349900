import React from "react";
import Header from "./Header";
import HeroSection from "./HeroSection";
import FeaturedScooters from "./FeaturedScooter";
import WhyChooseUs from "./WhyChooseUs";
import Testimonials from "./Testimonials";
import Footer from "./Footer";
import Buttons from "./Button";
import Pay from "./Pay";

function Home(){
    return(
        <div>
          <Header/>
          <HeroSection/>
          <FeaturedScooters/>
          <WhyChooseUs/>
          <Pay/>
          <Testimonials/>
          <Footer/>
          <Buttons/>
        </div>
    );
}

export default Home