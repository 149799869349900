import React from 'react';
import './AboutUsContent.css';

const AboutUsContent = () => {
  return (
    <section className="about-us">
      <div className="about-us-container">
        <h1 className="about-us-header">About Us</h1>
        <p className="about-us-description">
          Welcome to KelvinMotoBike, your reliable partner in exploring Zanzibar with ease and comfort. We are dedicated to providing you with the best scooter and motorbike rental experience, ensuring that your adventures on this beautiful island are unforgettable.
        </p>

        <div className="about-us-mission">
          <h2 className="about-us-subheader">Our Mission</h2>
          <p>
            Our mission is to offer top-quality, well-maintained scooters and motorbikes at competitive prices, backed by exceptional customer service. We aim to make your journey through Zanzibar smooth, safe, and enjoyable.
          </p>
        </div>

        <div className="about-us-vision">
          <h2 className="about-us-subheader">Our Vision</h2>
          <p>
            Our vision is to become the leading scooter and motorbike rental company in Zanzibar, known for our commitment to quality, safety, and customer satisfaction.
          </p>
        </div>

        <div className="about-us-values">
          <h2 className="about-us-subheader">Our Values</h2>
          <ul className="values-list">
            <li>Customer Satisfaction: We prioritize the happiness and satisfaction of our customers.</li>
            <li>Safety: We maintain our vehicles to the highest safety standards.</li>
            <li>Integrity: We operate with honesty and transparency in all our dealings.</li>
            <li>Innovation: We continuously improve our services to meet the changing needs of our customers.</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AboutUsContent;
