// Book.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Book.css';
import { FaTachometerAlt, FaCogs, FaMoneyBillAlt, FaShieldAlt } from 'react-icons/fa'; // Import shield icon
import vespa from '../images/vespa.jpg';
import vario from '../images/vario.jpg';
import pcx from '../images/pcx.jpg';
import filano from '../images/filano.jpg';
/*import quard from '../images/quard.jpg';*/
import click from '../images/click.jpg';
import xlr from '../images/xlr.jpeg';

import rava2 from '../images/rava2.jpeg';
import rava4 from '../images/rava4.jpeg';

const scooters = [
  {
    name: 'Vespa',
    image: vespa,
    maxSpeed: '120 km/h',
    mode: 'Automatic/Gear',
    price: '$30/day',
    helmetInfo: 'Two Helmet',
  },
  {
    name: 'Vario',
    image: vario,
    maxSpeed: '160 km/h',
    mode: 'Automatic',
    price: '$25/day',
    helmetInfo: 'Two Helmet',
  },
  {
    name: 'PCX',
    image: pcx,
    maxSpeed: '120 km/h',
    mode: 'Automatic',
    price: '$25/day',
    helmetInfo: 'Two Helmet',
  },
  {
    name: 'Filano',
    image: filano,
    maxSpeed: '120 km/h',
    mode: 'Automatic',
    price: '$30/day',
    helmetInfo: 'Two Helmet',
  },
 
  {
    name: 'Click',
    image: click,
    maxSpeed: '140 km/h',
    mode: 'Automatic',
    price: '$25/day',
    helmetInfo: 'Two Helmet',
  },

  /*
   {
    name: 'Quard Bike',
    image: quard,
    maxSpeed: '100 km/h',
    mode: 'Manual',
    price: '$150/day',
    helmetInfo: 'Two Helmet',
  },
  */
  {
    name: 'Honda XLR 250',
    image: xlr,
    maxSpeed: '140 km/h',
    mode: 'Manual',
    price: '$30/day',
    helmetInfo: 'Two Helmet',
  },

  {
    name: 'Toyota Rav4 2 Doors ',
    image: rava2,
    maxSpeed: '185 km/h',
    mode: 'Automatic',
    price: '$35/day',
    helmetInfo: 'Baby Seat Included',
  },
   {
    name: 'Toyota Rav4 4 Doors ',
    image: rava4,
    maxSpeed: '185 km/h',
    mode: 'Automatic',
    price: '$35/day',
    helmetInfo: 'Baby Seat Included',
  },
];

const Book = () => {
  const navigate = useNavigate();

  const handleBookClick = (scooter) => {
    // Navigate to the Order component with the selected scooter's details
    navigate('/order', { state: { selectedScooter: scooter } });
  };

  return (
    <div className="book-container">
      <h2 className="book-header">Book Your Ride</h2>
      <div className="book-cards-container">
        {scooters.map((scooter, index) => (
          <div key={index} className="book-card">
            <img src={scooter.image} alt={scooter.name} className="book-card-image" />
            <div className="book-card-details">
              <h3 className="book-card-name">{scooter.name}</h3>
              <p className="book-card-detail">
                <FaTachometerAlt className="icon-speed" /> Max Speed: {scooter.maxSpeed}
              </p>
              <p className="book-card-detail">
                <FaCogs className="icon-mode" /> Mode: {scooter.mode}
              </p>
              <p className="book-card-detail">
                <FaMoneyBillAlt className="icon-price" /> Price: {scooter.price}
              </p>
              <p className="book-card-detail">
                <FaShieldAlt className="icon-helmet" /> {scooter.helmetInfo}
              </p>
              <button className="book-button" onClick={() => handleBookClick(scooter)}>
                Book Now
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Book;
