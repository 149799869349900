import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Buttons from "./Button";
import Contact from "./Contact";

function ContactUs(){
    return(
        <div>
          <Header/>
          <Contact/>
          <Footer/>
          <Buttons/>
        </div>
    );
}

export default ContactUs