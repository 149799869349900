import React from 'react';
import './Pay.css';
import { FaRegMoneyBillAlt, FaEnvelope, FaCheckCircle } from 'react-icons/fa';

const Pay = () => {
  return (
    <div className="pay-container">
      <h2 className="pay-header">Complete Your Booking</h2>
      <p className="pay-description">
        Your booking is confirmed! Please pay upon arrival.
      </p>

      <div className="pay-icon-container">
        <FaRegMoneyBillAlt size={80} className="icon-cash" />
        <p className="pay-icon-text">Pay Later</p>
      </div>

      <div className="pay-receipt-container">
        <div className="pay-receipt">
          <FaEnvelope size={50} className="icon-email" />
          <p>You will receive a booking confirmation email shortly.</p>
        </div>
        <div className="pay-notice">
          <FaCheckCircle size={50} className="icon-check" />
          <p>Show the receipt and pay in cash when you arrive.</p>
        </div>
      </div>
    </div>
  );
};

export default Pay;
