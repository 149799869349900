import React from 'react';
import './WhyChooseUs.css';
import { FaMotorcycle, FaHeadset, FaCheckCircle, FaRegThumbsUp } from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';

const WhyChooseUs = () => {
    const navigate = useNavigate();
    const handleButtonClick = () => {
        navigate('/booking');
    }
  return (
    <section className="why-choose-us">
      <div className="container">
        <h2>Why Choose Us</h2>
        <p className="subtitle">Experience unmatched convenience, quality, and support with our scooter rental services.</p>
        <div className="features">
          <div className="feature-card">
            <FaMotorcycle className="feature-icon" />
            <h3>Variety of Scooters</h3>
            <p>Choose from a wide range of scooters to fit your style and needs.</p>
          </div>
          <div className="feature-card">
            <FaHeadset className="feature-icon" />
            <h3>24/7 Customer Support</h3>
            <p>We are here to assist you anytime, anywhere.</p>
          </div>
          <div className="feature-card">
            <FaCheckCircle className="feature-icon" />
            <h3>Simple Booking Process</h3>
            <p>Book your scooter in just a few clicks.</p>
          </div>
          <div className="feature-card">
            <FaRegThumbsUp className="feature-icon" />
            <h3>Top Quality Scooters</h3>
            <p>Our scooters are well-maintained and reliable for all your adventures.</p>
          </div>
        </div>
        <button className="cta-button" onClick={handleButtonClick}>Book Now</button>
      </div>
    </section>
  );
};

export default WhyChooseUs;
