import React from 'react';
import './FeaturedScooter.css';

// Import images
import filano from '../images/filano1.jpg';
import vario from '../images/vario.jpg';
import vespa from '../images/vespa.jpg';
import pcx from '../images/pcx.jpg';
import click from '../images/click.jpg';
/*import quard from '../images/quard.jpg';*/
import xlr from '../images/xlr.jpeg';

import rava2 from '../images/rava2.jpeg';
import rava4 from '../images/rava4.jpeg';

// Sample data for featured scooters
const scooters = [
  {
    id: 1,
    name: 'Filano',
    image: filano,
    description: 'Filano is known for its high performance and stylish design, featuring modern technology for a superior ride.',
    price: '$30/day'
  },
  {
    id: 2,
    name: 'Vario',
    image: vario,
    description: 'Vario offers a sleek cruiser experience with a focus on comfort and smooth handling for long rides.',
    price: '$25/day'
  },
  {
    id: 3,
    name: 'Vespa',
    image: vespa,
    description: 'Vespa, a classic choice, combines retro charm with modern eco-friendly features for a green commute.',
    price: '$30/day'
  },
  {
    id: 4,
    name: 'PCX',
    image: pcx,
    description: 'PCX is an advanced scooter with a powerful engine and great fuel efficiency, perfect for both city and highway rides.',
    price: '$25/day'
  },
  {
    id: 6,
    name: 'Click',
    image: click,
    description: 'Click is a compact and agile scooter designed for urban commuting, with excellent maneuverability and fuel economy.',
    price: '$25/day'
  },
/*
   {
    id: 5,
    name: 'Quad Bike',
    image: quard,
    description: 'The quad bike is rugged and versatile, built for both tough terrains and smooth rides. It offers reliability and power for various adventures.',
    price: '$150/day'
  },
  */
  {
    id: 7,
    name: 'Honda XLR',
    image: xlr,
    description: 'The Honda XLR is a versatile scooter, perfect for both city commutes and off-road fun, offering reliability and comfort.',
    price: '$35/day'
  },

  {
    id: 8,
    name: 'Toyota RAV4 (2 DOORS)',
    image: rava2,
    description: 'A compact, agile designed for drivers who prefer a sportier feel and easy maneuverability. Perfect for city driving and light off-road adventures',
    price: '$35/day'
  },

  {
    id: 9,
    name: 'Toyota RAV4 (4 DOORS)',
    image: rava4,
    description: 'The Toyota RAV4 4-doors offers a spacious interior, versatile cargo space and a confirtable ride. It is ideal for famlies.',
    price: '$35/day'
  },
  
];

const FeaturedScooters = () => {
  return (
    <section className="featured-scooters">
      <div className="container">
        <h2 className="section-title">Featured Scooters</h2>
        <div className="scooter-cards">
          {scooters.map((scooter) => (
            <div key={scooter.id} className="scooter-card">
              <div className="scooter-image-container">
                <img src={scooter.image} alt={scooter.name} className="scooter-image" />
              </div>
              <div className="scooter-info">
                <h3 className="scooter-name">{scooter.name}</h3>
                <p className="scooter-description">{scooter.description}</p>
                <div className="price-section">
                  <p className="scooter-price">{scooter.price}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturedScooters;
