// Order.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './Order.css';

const Order = () => {
  const location = useLocation();
  const { selectedScooter } = location.state || {};

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [pickupTime, setPickupTime] = useState('');
  const [dropoffTime, setDropoffTime] = useState('');
  const [pickupLocation, setPickupLocation] = useState(''); // New state
  const [dropoffLocation, setDropoffLocation] = useState(''); // New state
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [bookingID, setBookingID] = useState('');

  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    if (!selectedScooter) {
      // If no scooter is selected, redirect back to the booking page
      window.location.href = '/';
    }
  }, [selectedScooter]);

  const handleClearForm = () => {
    setFullName('');
    setEmail('');
    setStartDate('');
    setEndDate('');
    setPickupTime('');
    setDropoffTime('');
    setPickupLocation(''); // Clear pickup location
    setDropoffLocation(''); // Clear dropoff location
    setIsSubmitted(false);
  };

  function calculateTotalPrice(price, startDate, endDate, pickupTime, dropoffTime) {
    const dailyRate = parseInt(price.replace('$', ''));
    const start = new Date(`${startDate}T${pickupTime}:00`);
    const end = new Date(`${endDate}T${dropoffTime}:00`);
    const timeDifference = end - start;
    const totalHours = timeDifference / (1000 * 60 * 60);
    const days = Math.ceil(totalHours / 24);
    return dailyRate * Math.max(days, 1);
  }

  const handleGenerateReceipt = () => {
    const receiptContent = document.getElementById('receipt-content');
    const isPhone = window.innerWidth <= 480;

    html2canvas(receiptContent, {
      scale: isPhone ? 1.5 : 2,
      useCORS: true,
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: receiptContent.scrollWidth,
      windowHeight: receiptContent.scrollHeight,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', [210, 297]);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth - 20;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, Math.min(imgHeight, pdfHeight - 20));
      pdf.save(`Booking_Receipt_${bookingID}.pdf`);
    });
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    setBookingID(`BK-${Date.now()}`);
    setIsSubmitted(true);

    const data = {
      recipientEmail: email,
      fullName,
      scooterName: selectedScooter.name,
      startDate,
      endDate,
      numberOfDays: Math.ceil((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)),
      totalPrice: calculateTotalPrice(selectedScooter.price, startDate, endDate, pickupTime, dropoffTime),
      pickupTime,
      dropoffTime,
      pickupLocation, // Include pickup location in data
      dropoffLocation, // Include dropoff location in data
    };

    try {
      const response = await fetch('https://kelvinmotorbike.onrender.com/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log('Email sent successfully');
      } else {
        console.error('Failed to send email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <div className="order">
      <Header />
      <div className="order-form-container">
        {!isSubmitted ? (
          <div>
            <h2>Book for {selectedScooter?.name}</h2>
            <form onSubmit={handleConfirm} className="order-form">
              <table>
                <tr>
                  <td>
                    <label>Full Name:</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Email:</label>
                  </td>
                  <td>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Selected Vehicle:</label>
                  </td>
                  <td>
                    <input type="text" value={selectedScooter?.name} readOnly />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Start Date:</label>
                  </td>
                  <td>
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      min={today}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>End Date:</label>
                  </td>
                  <td>
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      min={startDate || today}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Pickup Time:</label>
                  </td>
                  <td>
                    <input
                      type="time"
                      value={pickupTime}
                      onChange={(e) => setPickupTime(e.target.value)}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Dropoff Time:</label>
                  </td>
                  <td>
                    <input
                      type="time"
                      value={dropoffTime}
                      onChange={(e) => setDropoffTime(e.target.value)}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Pickup Location:</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={pickupLocation}
                      onChange={(e) => setPickupLocation(e.target.value)}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Dropoff Location:</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={dropoffLocation}
                      onChange={(e) => setDropoffLocation(e.target.value)}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className="form-buttons">
                    <button type="submit" className="submit-button">Submit</button>
                    <button type="button" className="clear-button" onClick={handleClearForm}>Clear</button>
                  </td>
                </tr>
              </table>
            </form>
          </div>
        ) : (
          <div>
            <h3>Booking submitted successfully! Please check your email</h3>
            <div id="receipt-content" className="receipt-container">
              <div className="receipt-header">
                <h2>Kelvin Motorbikes and Scooter Rental</h2>
                <p>Phone: +255-674-299-674 | Email: info@kelvinmotobike.co.tz.</p>
                <p>Motorbike Avenue, Zanzibar</p>
              </div>

              <div className="receipt-body">
                <h3>Customer Information</h3>
                <p>
                  <strong>Name:</strong> {fullName}
                </p>
                <p>
                  <strong>Email:</strong> {email}
                </p>

                <h3>Booking Details</h3>
                <p>
                  <strong>Scooter:</strong> {selectedScooter?.name}
                </p>
                <p>
                  <strong>Start Date:</strong> {startDate}
                </p>
                <p>
                  <strong>End Date:</strong> {endDate}
                </p>
                <p>
                  <strong>Pickup Location:</strong> {pickupLocation}
                </p>
                <p>
                  <strong>Dropoff Location:</strong> {dropoffLocation}
                </p>
                <p>
                  <strong>Pickup Time:</strong> {pickupTime}
                </p>
                <p>
                  <strong>Dropoff Time:</strong> {dropoffTime}
                </p>
                <p>
                  <strong>Booking ID:</strong> {bookingID}
                </p>
                <p>
                  <strong>Total Price:</strong> ${calculateTotalPrice(selectedScooter.price, startDate, endDate, pickupTime, dropoffTime)}
                </p>
              </div>
              <div className="receipt-footer">
                <p>Thank you for choosing Kelvin Motorbikes and Scooter Rental!</p>
              </div>
            </div>

            <div className="button-container">
              <button className="submit-button" onClick={handleGenerateReceipt}>Generate Receipt</button>
              <button className="clear-button" onClick={handleClearForm}>Make Another Booking</button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Order;
