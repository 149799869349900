import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import './Testimonials.css';
import customer1 from '../images/customer1.jpg';
import customer2 from '../images/customer2.jpg';
import customer3 from '../images/customer3.jpg';
import customer4 from '../images/customer4.png';
import customer5 from '../images/customer5.webp';
import customer6 from '../images/customer6.jpg';

const testimonialsData = [
  {
    name: 'John Doe',
    image: customer1,
    quote: 'This service was fantastic! The scooter was in great condition and made my trip around Zanzibar so much fun.',
  },
  {
    name: 'Jane Smith',
    image: customer2,
    quote: 'I had a wonderful experience! The booking process was seamless and the staff was incredibly helpful.',
  },
  {
    name: 'Mark Wilson',
    image: customer3,
    quote: 'Highly recommend! The scooters are top-notch, and the freedom it gives to explore is unmatched.',
  },
  {
    name: 'Emily Johnson',
    image: customer4,
    quote: 'Amazing service! The scooter was easy to handle, and I loved the flexibility of exploring Zanzibar at my own pace.',
  },
  {
    name: 'Michael Brown',
    image: customer5,
    quote: 'The scooter rental was a highlight of our trip! Friendly staff and very well-maintained scooters. Will use again!',
  },
  {
    name: 'Sophia Martinez',
    image: customer6,
    quote: 'Fantastic experience! Affordable prices and the scooters were comfortable for long rides. A must-do in Zanzibar!',
  },
];


const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: () => nextTestimonial(),
    onSwipedRight: () => prevTestimonial(),
  });

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonialsData.length) % testimonialsData.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextTestimonial();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="testimonials" {...handlers}>
      <h2>What Our Customers Say</h2>
      <div className="testimonial-slider">
        <div
          className="testimonial-card"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {testimonialsData.map((testimonial, index) => (
            <div
              key={index}
              className={`testimonial-slide ${index === currentIndex ? 'active' : ''}`}
            >
              <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
              <p className="testimonial-quote">"{testimonial.quote}"</p>
              <h3 className="testimonial-name">{testimonial.name}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
