import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Buttons from "./Button";
import AboutUsContent from "./AboutUsContent";
function AboutUs(){
    return(
        <div>
          <Header/>
          <Buttons/>
          <AboutUsContent/>
          <Footer/>
        </div>
    );
}

export default AboutUs