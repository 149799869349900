import React from 'react';
import './HeroSection.css';

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h2 className="hero-title">Book Your Ride Today</h2>
        <p className="hero-description">
          Discover Zanzibar at your own pace with our premium scooters and motorbikes. 
          Enjoy the freedom to explore the island's stunning landscapes with comfort and style.
        </p>
        <a href="/booking" className="hero-button">
          Get Started
        </a>
      </div>
    </section>
  );
};

export default HeroSection;
