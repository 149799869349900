import React from 'react';
import './Button.css';
import { FaWhatsapp, FaArrowUp } from 'react-icons/fa';

const Buttons = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {/* WhatsApp Button on the Right */}
      <a
        href="https://wa.me/+255674299674?text=Hello%20KelvinMotorBikes"
        className="whatsapp-button"
        target="_blank"
        rel="noopener noreferrer"
      > 
        &nbsp; &nbsp;<FaWhatsapp className="whatsapp-icon" />&nbsp;
        How I can Help you? &nbsp;
      </a>

      {/* Scroll-to-Top Button on the Left */}
      <button className="scroll-to-top-button" onClick={handleScrollToTop}>
        <FaArrowUp className="arrow-icon" />
      </button>
    </>
  );
};

export default Buttons;
