import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Buttons from "./Button";
import Book from "./Book";

function Booking(){
    return(
        <div>
          <Header/>
          <Book/>
          <Footer/>
          <Buttons/>
        </div>
    );
}

export default Booking