// Footer.js
import React from 'react';
import './Footer.css'; // Ensure you keep this CSS file for styling
import { FaPhone, FaEnvelope, FaGlobe, FaFacebook, FaTwitter, FaInstagram, FaMapMarkerAlt } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section footer-logo">
        <h2>KelvinMotorBike</h2>
        <p>For Zanzibar Motorbikes and Scooter Rental</p>
      </div>

      <div className="footer-section footer-contact">
        <h3>Contact and Support</h3>
        <ul>
          <li><FaPhone /> +255674299674</li>
          <li><FaEnvelope /> info@kelvinmotobike.co.tz</li>
          <li><FaGlobe /> www.kelvinmotobike.co.tz</li>
        </ul>
      </div>

      <div className="footer-section footer-about">
        <h3>About Us</h3>
        <hr />
        <p>We offer a range of scooters and motorbikes for tourists looking to explore the beautiful Island of Zanzibar. Enjoy the freedom and convenience of our rentals for a memorable trip.</p>
        <div className="social-icons">
          <a href="https://facebook.com/zanmotorbike" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
          <a href="https://twitter.com/zanmotorbike" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
          <a href="https://instagram.com/kelvinmotorbikesrental" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
        </div>
      </div>

      <div className="footer-section footer-visit">
        <h3>Visit Us</h3>
        <hr />
        <p><FaMapMarkerAlt /> Motorbike Avenue, Zanzibar, Tanzania</p>
        <p><FaMapMarkerAlt /> Pemba Airport, Zanzibar, Tanzania</p>
        <a href="https://maps.google.com/?q=Q6R8+3M7+Zanzibar" target="_blank" rel="noopener noreferrer">Find us on Google Maps</a>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} KelvinMotorBike. All rights reserved.<br/> 
        Developed And Maintained By <a href='https://techmento.co.tz'>Techmento</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
