import React, { useState, useEffect } from 'react';
import './Header.css';

import foro from '../images/back13.jpg';
import mtoni from '../images/back1.jpg';
import town from '../images/back4.jpg';
import nakupenda from '../images/back7.jpg';
import back from '../images/back12.jpg';
import logo from '../images/logo.png'; // Adjust path as needed

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSentence, setCurrentSentence] = useState(0);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const slides = [foro, mtoni, town, nakupenda, back];
  const sentences = [
    'Explore Zanzibar with Ease',
    'Your Ride, Your Adventure',
    'Discover More on Two Wheels',
    'Scooters for Every Journey',
    'Freedom to Roam Zanzibar',
  ];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);

    const sentenceInterval = setInterval(() => {
      setCurrentSentence((prevSentence) => (prevSentence + 1) % sentences.length);
    }, 8000);

    return () => {
      clearInterval(slideInterval);
      clearInterval(sentenceInterval);
    };
  }, [slides.length, sentences.length]);

  return (
    <header className="header">
      <div className="header-inner">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>

        <nav className={`nav ${menuOpen ? 'open' : ''}`}>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/booking">Booking</a></li>
            <li><a href="/aboutUs">About Us</a></li>
            <li><a href="/contactUs">Contact Us</a></li>
          </ul>
        </nav>

        <div className="menu-icon" onClick={toggleMenu}>
          <span className="menu-bar"></span>
          <span className="menu-bar"></span>
          <span className="menu-bar"></span>
        </div>

        <div className="slideshow">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`slide ${index === currentSlide ? 'active' : ''}`}
              style={{
                backgroundImage: `url(${slide})`,
              }}
            ></div>
          ))}
        </div>

        <div className="header-text">
          <h1>Kelvin Motorbikes and Scooter Rental</h1>
          <p className="header-sentence">
            {sentences[currentSentence]}
          </p>
        </div>
      </div>
    </header>
  );
};

export default Header;
